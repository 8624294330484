<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
            sort-by="order_id"
            :sort-desc="true"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="grey" dark class="mb-2 mr-2" @click="viewOrderSummary" small>
                    Summary
                </v-btn>
                <!-- <v-btn color="green" dark class="mb-2" @click="produksi" small>
                    Produksi
                </v-btn> -->

            </v-toolbar>
            <!-- <v-toolbar flat>
              <v-row class="ml-1" align="center">
                <v-col cols="2">Hari Produksi</v-col>
                <v-col cols="4">
                  <v-menu v-model="dateProdMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="productionDate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined class="compact-form"></v-text-field>
                    </template>
                    <v-date-picker v-model="productionDate" :min="minimumProductionDate"></v-date-picker>
                  </v-menu>

                </v-col>
                <v-col class="caption text-right pr-5">
                  Waktu Sekarang  : {{currentTime}} WIB
                </v-col>
              </v-row>

            </v-toolbar> -->
            </template>
            <!-- <template v-slot:[`item.select`]="{ item }">
              <v-checkbox v-model="selectedOrders" :value="item.order_id" dense hide-details class="shrink mr-2 mt-0" :disabled="(item.status==1)?true:false" v-if="!item.status"></v-checkbox>
              <v-icon small v-if="item.status">
                mdi-check
              </v-icon>
            </template> -->
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="viewItem(item.order_batch)" class="mr-2">
                  mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="editItem(item.order_batch)">
                  mdi-pencil
              </v-icon>
            </template> -->
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.production_date`]="{ item }">
              <v-layout>
                <span v-if="!item.isEdit">{{item.production_date | moment}}</span>
                <v-menu v-else v-model="item.dateMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="item.production_date" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined class="compact-form" style="max-width:120px!important"></v-text-field>
                  </template>
                  <v-date-picker v-model="item.production_date" :min="minimumProductionDate"></v-date-picker>
                </v-menu>
                <v-icon v-if="item.isEdit" small class="pb-1" color="red" @click="cancelEdit(item)">mdi-close</v-icon>
                <v-icon v-if="item.isEdit" small class="pb-1 pl-1" color="green" @click="saveDate(item)">mdi-content-save</v-icon>
                <v-icon v-if="!item.isEdit && item.status==0 && $store.state.currentPagePriv.update" small class="pb-1 pl-1" @click="editDate(item)">mdi-pencil</v-icon>
              </v-layout>
            </template>
            <template v-slot:[`item.delivery_date`]="{ item }">
              {{item.delivery_date | moment}}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              {{(item.status)?'Complete':'Pending'}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="viewItem(item.order_id)" class="mr-2" v-if="item.status==1">
                    mdi-eye
                </v-icon>
                <v-icon small class="mr-2" @click="editItem(item.order_id)" v-if="(!item.status && $store.state.currentPagePriv.update) || profile.role_code=='SUP'">
                    mdi-pencil
                </v-icon>
            </template>

        </v-data-table>
      <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
      <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    <v-dialog v-model="dialogSummary" scrollable max-width="90%">
      <v-card>
        <v-card-title>Order Summary</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 90%;">
          <v-simple-table fixed-header height="500px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Volume
                    </th>
                    <th class="text-left"  v-for="(date, idx) in summaryDates" :key="idx">
                      {{date |moment2}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="size in summarySizes" :key="size">
                    <td>{{ size }}ml</td>
                    <td v-for="date in summaryDates" :key="date">{{ getQty(size, date) | numbers }} btl</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Total Volume</td>
                    <td class="font-weight-bold" v-for="date in summaryDates" :key="date">{{ getTotal(date) | numbers }} ml</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">490ml</td>
                    <td class="font-weight-bold" v-for="date in summaryDates" :key="date">{{ getTotal490(date) | numbers }} btl</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogSummary = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data: () => ({
      headers: [
        { text: 'Order Code', value: 'order_id' },
        { text: 'Group', value: 'order_category' },
        { text: 'Hari Produksi', value: 'production_date' },
        { text: 'Hari Kirim', value: 'delivery_date' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Status Order', value: 'status' },
        { text: '', value: 'actions' },
        // { text: 'Select', value: 'select', sortable: false },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      saved: false,
      saveError: false,
      orderSummary: [],
      dialogSummary: false,
      summaryDates: [],
      summarySizes: [],
    }),

    computed: {
      minimumProductionDate(){
        let date = moment().format("YYYY-MM-DD")
        // if (moment().hour()>=10)
        //   date = moment().add(1, 'd').format("YYYY-MM-DD")
        return date;
      },
      currentTime() {
        return moment().format("DD-MMM-YYYY hh:mm")
      },
      profile () {
        return this.$store.state.profile
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },
    mounted() {
    },
    filters: {
        moment: function (date) {
          if (date)
            return moment(date).format('ddd, D MMM YYYY')
          else return 'Undefined'
        },
        moment2: function (date) {
          if (date)
            return moment(date).format('ddd, D MMM')
          else return 'Undefined'
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }

    },

    methods: {
      async initialize () {
        let response = null
        if (this.profile.role_code=='SUP')
          response = await this.$store.dispatch('getObjs', {apidomain:'others/planningorderssupadm', parameter:''})
        else
          response = await this.$store.dispatch('getObjs', {apidomain:'others/planningorders', parameter:''})

        this.items = response.data
      },

      getQty(size, date) {
        const item = this.orderSummary.find(el => el.size == size && el.production_date == date)
        console.log(item)
        if (item)
          return (Number(item.qty))
        else return 0
      },

      getTotal(date) {
        const items = this.orderSummary.filter(el => el.production_date == date)
        if (items.length>0)
          return items.reduce((acc, item) => acc + (parseInt(item.size)*parseInt(item.qty)), 0)
        else return 0
      },

      getTotal490(date) {
        return Math.round(this.getTotal(date)/490)
      },

      editDate(item) {
        this.$set(item, 'isEdit', true)
        this.$set(item, 'dateMenu', false)
      },

      cancelEdit(item) {
        this.$set(item, 'isEdit', false)
        this.initialize()
      },

      async saveDate(item) {
        const prod_no = moment(item.production_date).format("YYYYMMDD")
        let orders = {orders:[]}
        orders.orders.push({
          order_batch: this.$route.params.batch,
          order_id: item.order_id,
          prod_no: prod_no,
          production_date: item.production_date
        })
        console.log(orders)
        const docs = await this.$store.dispatch('order/putObj', {apidomain: 'others/multipleorders', payload:orders})
        console.log(docs)
        if (docs.data.length) {
          this.saved = true
          this.initialize()
        }
      },

      async viewOrderSummary() {
        let response = await this.$store.dispatch('getObjs', {apidomain:'others/ordersummary', parameter:''})
        this.orderSummary = response.data
        this.summaryDates = [...new Set(this.orderSummary.map(item => item.production_date))];
        this.summarySizes = [...new Set(this.orderSummary.map(item => Number(item.size)))]; 
        this.summaryDates.sort();
        this.summarySizes.sort(function(a, b){return a-b});
        this.dialogSummary = true
      },
      viewItem(order_id) {
        this.$router.push('/uploadorderplan/001/'+order_id)
      },

      editItem(order_id) {
        this.$router.push('/uploadorderplan/001/'+order_id)
      },

      // async produksi() {
      //   const prod_no = moment(this.productionDate).format("YYYYMMDD")
      //   let orders = {orders:[]}
      //   this.selectedOrders.forEach(order => {
      //     orders.orders.push({
      //       order_batch: this.$route.params.batch,
      //       order_id: order,
      //       prod_no: prod_no,
      //       production_date: this.productionDate
      //     })
      //   })
      //   console.log(orders)
      //   const docs = await this.$store.dispatch('order/putObj', orders)
      //   console.log(docs)
      //   if (docs.data.length) {
      //     this.saved = true
      //     this.selectedOrders = []
      //     this.initialize()
      //   }
      // }

    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>